<template>
    <div class="certificate-list-content">
        <div class="top">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item>报告管理</el-breadcrumb-item>
                <el-breadcrumb-item>查看详情</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="btn-box">
                <el-button type="primary" plain @click="batchPrint">批量打印</el-button>
                <el-button type="primary" @click="backBtn">返回</el-button>
            </div>
        </div>
        <div class="middle-wrap">
            <div class="search-content">
                <el-input v-model="searchCertificateValue"
                          placeholder="请输入内容"
                          slot="prepend"
                          class="w300-input"
                          @keyup.enter.native="certificateSearch">
                </el-input>
            </div>
        </div>
        <el-table :data="studentListTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="100"></el-table-column>
            <el-table-column prop="student_name" label="学生名称" align="center" width="200"></el-table-column>
            <el-table-column prop="class_name" label="班级" align="center"></el-table-column>
            <el-table-column prop="exam_name" label="考试名称" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" @click="viewReport(scope.row)" :underline="false">查看报告</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <pagination class="pages-center" :total="total" @current-change="handleCurrentChange"/>
    </div>
</template>

<script>
    import pagination from '@/components/pagination.vue';
    import {reportManagementStuList, examSeeStuReport} from "@/utils/apis"

    export default {
        name: "reportDetail",
        components: {
            pagination
        },
        created() {
            this.getList();
        },
        data() {
            return {
                exam_id: this.$route.query.id,
                searchCertificateValue: '',//搜索证书
                studentListTable: [],
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
                multipleSelection: []
            }
        },
        methods: {
            getList() {
                let param = {
                    exam_id: this.exam_id,
                    page: this.currentPageNum,
                    paging: 1,
                    pageSize: this.eachPageNum
                }
                if (this.searchCertificateValue != '') {
                    param.search = this.searchCertificateValue;
                }
                reportManagementStuList(param).then(res => {
                    this.studentListTable = res.data.data;
                    this.total = res.data.total
                }).catch(err => {
                    console.error(err)
                })
            },
            handleSelectionChange(val) {
                let arr = [];
                val.forEach(item => {
                    arr.push(item.student_id)
                })
                this.multipleSelection = arr;
            },
            batchPrint() {
                this.$message.warning('敬请期待！')
                // let studentIds = ''
                // studentIds = this.multipleSelection.join('#')
                // if(studentIds !== ''){
                //     let param = {
                //         exam_id: this.exam_id,
                //         student_id: studentIds
                //     }
                //     examSeeStuReport(param).then(res => {
                //         this.studentInfo = res.data;
                //         this.isInfo = true;
                //     }).catch(err => {
                //         console.error(err)
                //     })
                // }
            },
            // 返回
            backBtn() {
                this.$router.push({
                    path: '/evaluationCenter/reportManagement',
                });
            },
            //搜索事件
            certificateSearch() {
                this.getList()
            },
            viewReport(row) {
                this.$router.push({
                    path: '/evaluationCenter/reportManagement/singleReport',
                    query: {
                        id: this.exam_id,
                        stu_id: row.student_id
                    }
                })
            },
            //切换分页
            handleCurrentChange(val) {
                this.currentPageNum = val;
                this.getList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .certificate-list-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;

        .top {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #F1F5FF;
            height: 40px;
            padding-bottom: 10px;

            .genera-breadcrumb {
                display: flex;
                align-items: center;

                &:before {
                    content: unset;
                }
            }
        }

        .middle-wrap {
            padding: 15px 0;
            display: flex;
            justify-content: space-between;

            .question-class {
                display: flex;

                .question-item {
                    display: flex;
                    align-items: center;

                    &:last-child {
                        padding-left: 48px;
                    }

                    .w300-cascader {
                        width: 300px;
                    }

                    .W200-select {
                        width: 200px;
                    }

                    .text {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
</style>